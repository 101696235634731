import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './components/privateRoute';
import { LoginPage } from './pages/login';
import withPermissions from './components/hoc/withPermissions';

// NOTA: revisar por que no puedo llamar la carpeta sin el nombre del archivo
const ActvIndividual = lazy(() => import('./pages/actividades/individual/index'));
const ActvIndividualEntrada = lazy(() => import('./pages/actividades/individual/entradas/entrada'));
const ActvIndividualEntradaEditar = lazy(() =>
  import('./pages/actividades/individual/entradas/editar')
);
const ActvIndividualEntradaTarifa = lazy(() =>
  import('./pages/actividades/individual/entradas/tarifa')
);
const ActvIndividualNuevoServicio = lazy(() =>
  import('./pages/actividades/individual/entradas/nuevoServicio')
);
const ActvCategorias = lazy(() => import('./pages/actividades/categorias'));
const ActvPickups = lazy(() => import('./pages/actividades/pickups'));
const ActvTemporadas = lazy(() => import('./pages/actividades/temporadas'));
const CambiosTemporadas = lazy(() => import('./pages/actividades/temporadas/cambiosTempotadas'));
const ContenidoEntradas = lazy(() => import('./pages/actividades/individual/entradas/content'));
//Tours
const ActvIndividualTour = lazy(() => import('./pages/actividades/individual/tours/tour'));
const ActvIndividualTourEditar = lazy(() => import('./pages/actividades/individual/tours/editar'));
const ActvIndividualTourTarifa = lazy(() => import('./pages/actividades/individual/tours/tarifa'));
const ActvIndividualNuevoServicioTour = lazy(() =>
  import('./pages/actividades/individual/tours/nuevoServicio')
);
const ContenidoTours = lazy(() => import('./pages/actividades/individual/tours/content'));
const BookingManager = lazy(() => import('./pages/actividades/bookingManager'));
const LimitPaxService = lazy(() => import('./pages/actividades/limitPaxService'));
const TabulatorPercentRep = lazy(() => import('./pages/actividades/tabulator-percent-rep'));
// TRANSPORTACION
const transportacionProveedores = lazy(() => import('./pages/transportacion/traslado/providers'));
const transportacion = lazy(() => import('./pages/transportacion/index'));
const transportacionTemporadas = lazy(() => import('./pages/transportacion/temporadas'));
const transportacionUnidades = lazy(() => import('./pages/transportacion/unidades'));
const transportacionZonas = lazy(() => import('./pages/transportacion/zonas'));
const transportacionZonasAbierto = lazy(() => import('./pages/transportacion/zonasAbierto'));
const transportacionHorasAbierto = lazy(() => import('./pages/transportacion/horasAbierto'));
const transportacionZonasByHotel = lazy(() => import('./pages/transportacion/zonas/hoteles'));
const transportacionZonasAbiertoByHotel = lazy(() =>
  import('./pages/transportacion/zonasAbierto/hoteles')
);
const transportacionExcursion = lazy(() => import('./pages/transportacion/indexExcursion'));
const transportacionTranslateAddRate = lazy(() =>
  import('./pages/transportacion/traslado/agregarTarifa')
);
const transportacionTranslateAddOpenRate = lazy(() =>
  import('./pages/transportacion/traslado/agregarTarifaAbierta')
);
const ActvPaquete = lazy(() => import('./pages/actividades/paquetes/index.js'));
// AUTOS
const autos = lazy(() => import('./pages/autos/old_index'));
const autosAgregar = lazy(() => import('./pages/autos/agregar'));
const autosTemporadas = lazy(() => import('./pages/autos/temporadas'));
// DISCOS
const discos = lazy(() => import('./pages/discos'));
const discosEditar = lazy(() => import('./pages/discos/edit'));
const discosTarifas = lazy(() => import('./pages/discos/tarifas'));
const discosNuevoServicio = lazy(() => import('./pages/discos/nuevoServicio'));
const discosTemporadas = lazy(() => import('./pages/discos/temporadas'));
// CUPONES
const cupones = lazy(() => import('./pages/cupones'));
const cuponesAgregar = lazy(() => import('./pages/cupones/agregar'));
// USUARIOS
const usuarios = lazy(() => import('./pages/usuarios'));
const usuariosAgregar = lazy(() => import('./pages/usuarios/agregar'));
//
//const usuarioEditar = lazy(() => import('./pages/usuarios/agrega'));
const agencias = lazy(() => import('./pages/usuarios/listaAgencias'));
const agenciasAgregar = lazy(() => import('./pages/usuarios/agregarAgencia'));
const proveedores = lazy(() => import('./pages/usuarios/listaProveedores'));
const proveedoresAgregar = lazy(() => import('./pages/usuarios/agregarProveedor'));
const ListCanales = lazy(() => import('./pages/usuarios/canales.js'));

//ROLES
const roles = lazy(() => import('./pages/roles/Roles'));
const rolesUsuarios = lazy(() => import('./pages/roles/RolesAsignacion'));

// MONEDAS
const monedas = lazy(() => import('./pages/monedas'));
const contable = lazy(() => import('./pages/monedas/accountCurrency'));
// PROVEEDORES
const ProveedoresConfirmation = lazy(() => import('./pages/proveedores'));

//CMS
const CmsPromociones = lazy(() => import('./pages/cms/promociones'));
const CmsServicesList = lazy(() => import('./pages/cms/serviceslist'));
const CmsAgregarPromocion = lazy(() => import('./pages/cms/promociones/agregar'));
const CmsEditarPromocion = lazy(() => import('./pages/cms/promociones/editar'));
const CmsTours = lazy(() => import('./pages/cms/tours'));
const CmsAgregarParque = lazy(() => import('./pages/cms/tours/agregar'));
const CmsEditarTour = lazy(() => import('./pages/cms/tours/editar'));
const CmsCategorias = lazy(() => import('./pages/cms/categoria'));
const CmsTraslados = lazy(() => import('./pages/cms/traslados'));
const CmsLandings = lazy(() => import('./pages/cms/landings'));
const CmsLandingUpdate = lazy(() => import('./pages/cms/landings/updateLanding'));
const CmsLandingCreate = lazy(() => import('./pages/cms/landings/createLanding'));

const CmsTTosTemplates = lazy(() => import('./pages/cms/landings-ttos/templates'));
const CmsTTosTemplatesCreate = lazy(() => import('./pages/cms/landings-ttos/templates/create'));
const CmsTTosTemplatesEdit = lazy(() => import('./pages/cms/landings-ttos/templates/edit'));
const CmsTTosLandings = lazy(() => import('./pages/cms/landings-ttos/landings/index.jsx'));
const CmsTTosLandingsCreate = lazy(() => import('./pages/cms/landings-ttos/landings/create.jsx'));
const CmsTTosLandingsEdit = lazy(() => import('./pages/cms/landings-ttos/landings/edit.jsx'));
const CmsTTosDefaultConfig = lazy(() => import('./pages/cms/landings-ttos/default-config'));
const CmsTTosDefaultConfigCreate = lazy(() => import('./pages/cms/landings-ttos/default-config/create'));
const CmsTTosDefaultConfigEdit = lazy(() => import('./pages/cms/landings-ttos/default-config/edit'));

const CmsEditarTraslado = lazy(() => import('./pages/cms/traslados/editar'));

const CmsPaquetes = lazy(() => import('./pages/cms/paquetes'));
const CmsAgregarPaquete = lazy(() => import('./pages/cms/paquetes/agregar'));
const CmsEditarPaquete = lazy(() => import('./pages/cms/paquetes/editar'));

const CmsEditarServicio = lazy(() => import('./pages/cms/tours/servicios/formulario'));
const CmsLista = lazy(() => import('./pages/cms/lista'));

const LandingTransportation = lazy(() => import('./pages/cms/landing-transportation'));
const UpdateSectionLandingTransportation = lazy(() => import('./pages/cms/landing-transportation/form/UpdateSections.jsx'));

//Contabilidad
const Cuentas = lazy(() => import('./pages/contabilidad/Cuentas/index'));
const AgregarCuenta = lazy(() => import('./pages/contabilidad/Cuentas/agregar'));
const EditCuenta = lazy(() => import('./pages/contabilidad/Cuentas/editar'));

const CuentaNivel = lazy(() => import('./pages/contabilidad/CuentaNiveles'));
const AgregarCuentaNivel = lazy(() => import('./pages/contabilidad/CuentaNiveles/agregar'));
const EditCuentaRecurso = lazy(() => import('./pages/contabilidad/cuentaNiveles/editar'));

const CuentasListAll = lazy(() => import('./pages/contabilidad/cuentaList'));
const NuevaCuenta = lazy(() => import('./pages/contabilidad/addCuenta'));

const CuentasAdicionar = lazy(() => import('./pages/contabilidad/cuentasCategoria'));
const AgregarCuentaCategoria = lazy(() => import('./pages/contabilidad/cuentasCategoria/agregar'));
const EditCuentaCategoria = lazy(() => import('./pages/contabilidad/cuentasCategoria/edit'));

const AdicionarTipo = lazy(() => import('./pages/contabilidad/adicionarTipo'));
const AgregarTipo = lazy(() => import('./pages/contabilidad/adicionarTipo/agregar'));
const EditTipo = lazy(() => import('./pages/contabilidad/adicionarTipo/edit'));

const CuentaCostos = lazy(() => import('./pages/contabilidad/cuentasCostos'));
const AgregarCuentaCosto = lazy(() => import('./pages/contabilidad/cuentasCostos/agregar'));
const EditCuentaCostos = lazy(() => import('./pages/contabilidad/cuentasCostos/edit'));

const AdicionarTipoCategoria = lazy(() => import('./pages/contabilidad/categoryConTipo'));
const AgregarTipoCuentaCategoria = lazy(() =>
  import('./pages/contabilidad/categoryConTipo/agregar')
);
const EditTipoCategoriaCuenta = lazy(() => import('./pages/contabilidad/categoryConTipo/edit'));

const ReporteVentaContable = lazy(() => import('./pages/contabilidad/reportePorCuentaContable/'));

const GrupoCuentas = lazy(() => import('./pages/contabilidad/grupoCuentas'));
const GrupoAgregar = lazy(() => import('./pages/contabilidad/grupoCuentas/agregar'));
const GrupoEdit = lazy(() => import('./pages/contabilidad/grupoCuentas/edit'));

// RBAC
const UsersList = lazy(() => import('./pages/rbac/users/UsersList'));
const UserView = lazy(() => import('./pages/rbac/users/UserView'));

const RolesList = lazy(() => import('./pages/rbac/roles/RolesList'));
const RolesView = lazy(() => import('./pages/rbac/roles/RolesView'));
const RolesCreate = lazy(() => import('./pages/rbac/roles/RolesCreate'));

const PermissionsList = lazy(() => import('./pages/rbac/permissions/PermissionsList'));
const PermissionsView = lazy(() => import('./pages/rbac/permissions/PermissionsView'));
const PermissionsCreateExtranet = lazy(() =>
  import('./pages/rbac/permissions/PermissionsCreateExtranet')
);
const PermissionsCreateCrm = lazy(() => import('./pages/rbac/permissions/PermissionsCreateCrm'));
const ReplicatePermissions = lazy(() => import('./pages/rbac/permissions/ReplicatePermissions'));
const ReplicateUsers = lazy(() => import('./pages/rbac/permissions/ReplicateUsers'));

//Propinas
const Propinas = lazy(() => import('./pages/propinas'));
const Politicas = lazy(() => import('./pages/politicas'));

// const metasEjecutivoIndex = lazy(() => import('./pages/actividades/metas/metasEjecutivo/UserGoalsIndex'));
// const metasEjecutivoCreate = lazy(() => import('./pages/actividades/metas/metasEjecutivo/UserGoalsCreate'));
// const metasEjecutivoUpdate = lazy(() => import('./pages/actividades/metas/metasEjecutivo/UserGoalsUpdate'));
const metasSubGerenteIndex = lazy(() => import('./pages/actividades/metas/metasSubGerente/UserGoalsIndex'));
const metasSubGerenteCreate = lazy(() => import('./pages/actividades/metas/metasSubGerente/UserGoalsCreate'));
const metasSubGerenteUpdate = lazy(() => import('./pages/actividades/metas/metasSubGerente/UserGoalsUpdate'));
const metasSupervisorIndex = lazy(() => import('./pages/actividades/metas/metasSupervisor/UserGoalsIndex'));
const metasSupervisorReplicar = lazy(() => import('./pages/actividades/metas/metasSupervisor/DuplicarParametros'));
const metasSupervisorCreate = lazy(() => import('./pages/actividades/metas/metasSupervisor/UserGoalsCreate'));
const metasSupervisorUpdate = lazy(() => import('./pages/actividades/metas/metasSupervisor/UserGoalsUpdate'));

const PromoServicesDetailsIndex = lazy(() => import('./pages/promo-services-details/PromoServicesDetailsIndex.jsx'));
const ReplicarProductos = lazy(() => import('./pages/users/replicarProductos/index'));
const ServicesAvailabilityIndex = lazy(() => import('./pages/services-availability/ServicesAvailabilityIndex.jsx'));

const AvailabilityDiscs = lazy(() => import('./pages/services-availability/discs/AvailabilityDiscs.jsx'));
const AvailabilityTours = lazy(() => import('./pages/services-availability/tours/AvailabilityTours.jsx'));
const AvailabilityTickets = lazy(() => import('./pages/services-availability/tickets/AvailabilityTickets.jsx'));
const AvailabilityPackages = lazy(() => import('./pages/services-availability/packages/AvailabilityPackages.jsx'));

const PaymentRulesIndex = lazy(() => import('./pages/payment-rules/PaymentRulesIndex.jsx'));
const PaymentsRulesCreate = lazy(() => import('./pages/payment-rules/PaymentsRulesCreate.jsx'));
const PaymentRulesUpdate = lazy(() => import('./pages/payment-rules/PaymentRulesUpdate'));


const LabelsIndex = lazy(() => import('./pages/labels'));
const LabelsCreate = lazy(() => import('./pages/labels/Create'));
const LabelsUpdate = lazy(() => import('./pages/labels/Update'));

const ServicesLabelsIndex = lazy(() => import('./pages/services-labels/ServicesLabelsIndex'));
const TicketsParksList = lazy(() => import('./pages/services-labels/tickets/ParksList'));
const TicketsServicesList = lazy(() => import('./pages/services-labels/tickets/ServicesList'));

const ToursParksList = lazy(() => import('./pages/services-labels/tours/ParksList'));
const ToursServicesList = lazy(() => import('./pages/services-labels/tours/ServicesList'));

const UserInceptives = lazy(() => import('./pages/inceptives-reps/Index'));
const UserInceptivesCreate = lazy(() => import('./pages/inceptives-reps/Create'));

// cms promotions

const PromosIndex = lazy(() => import('./pages/cms/promos/Index'));
const PromosCreate = lazy(() => import('./pages/cms/promos/Create'));
const PromosUpdate = lazy(() => import('./pages/cms/promos/Update'));

const DepartmentsIndex = lazy(() => import('./pages/departments/Index'));

const ServicesRulesIndex = lazy(() => import('./pages/services-rules/ServicesRulesIndex'));
const TicketsParksRulesList = lazy(() => import('./pages/services-rules/tickets/ParksList'));
const TicketsServicesRulesList = lazy(() => import('./pages/services-rules/tickets/ServicesList'));

const ToursParksRulesList = lazy(() => import('./pages/services-rules/tours/ParksList'));
const ToursServicesRulesList = lazy(() => import('./pages/services-rules/tours/ServicesList'));
const ComprobantePago = lazy(() => import('./pages/comprobantePago'));

const LoginLogsIndex = lazy(() => import('./pages/login-logs/LoginLogsIndex'));
const ServicesInceptivesLabels = lazy(() => import('./pages/services-labels/ServicesInceptivesLabels'));

const SaveBookingLogs = lazy(() => import('./pages/booking-logs/SaveBookingLogs'));
const UsersIndex = lazy(() => import('./pages/users/UsersIndex'));
const NimServicesIndex = lazy(() => import('./pages/nim-services/Index'));

const LabelsAndRules = lazy(() => import('./pages/services-labels-and-rules/Index'));

const CmsDiscsIndex = lazy(() => import('./pages/cms/discs'));
const CmsDiscsCreate = lazy(() => import('./pages/cms/discs/Create'));
const CmsDiscsUpdate = lazy(() => import('./pages/cms/discs/Update'));
const CmsDiscsEditService = lazy(() => import('./pages/cms/discs/services/EditService'));

//Banners web
const CmsBannersIndex = lazy(() => import('./pages/cms/banners'));

// CatalogsCategories
const CatalogCategories = lazy(() => import('./pages/catalog-categories/Index'));

// CatalogAmenities
const CatalogAmenities = lazy(() => import('./pages/catalog-amenities/Index'));

// Cms Testimonios
const CmsTestimonialsIndex = lazy(() => import('./pages/cms/testimonials'));

const Welcome = lazy(() => import('./pages/welcome/Index'));

//Sections web
const CmsSections = lazy(() => import('./pages/cms/sections'));
const CmsSectionsTemplates = lazy(() => import('./pages/cms/sections/plantillas/'));
const CmsSectionsTemplatesCreate = lazy(() => import('./pages/cms/sections/plantillas/Create'));
const CmsSectionsTemplateUpdate = lazy(() => import('./pages/cms/sections/plantillas/Update'));

//New promotions web
const CmsPromotions = lazy(() => import('./pages/cms/promotions_new'));
const CmsPromotionsCreate = lazy(() => import('./pages/cms/promotions_new/Create'));

const CmsPromotionsUpdate = lazy(() => import('./pages/cms/promotions_new/Update'));

const combosDiscos = lazy(() => import('./pages/discos/combos/index'))

const Clip3D = lazy(() => import('./pages/cms/clip-3d'));

const ConfigurationView = lazy(() => import('./pages/usuarios/canales/configurationView.jsx'));

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(24,144,255,10%)',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="https://flevix.com/wp-content/uploads/2019/07/Alternate-Preloader.gif" alt="" />
    </div>
  );
};

const Router = ({ isAuth, user }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/welcome" />} />
        <Route exact path="/login" component={LoginPage} />
        {/*TODO: Hablar con Elio para ver que pedos con los roles */}
        <PrivateRoute exact path="/welcome" component={Welcome} auth={isAuth} />
        <PrivateRoute exact path="/actividad/individual" component={ActvIndividual} auth={isAuth} />
        <PrivateRoute exact path="/actividad/paquete" component={ActvPaquete} auth={isAuth} />
        <PrivateRoute exact path="/actividad/categorias" component={ActvCategorias} auth={isAuth} />
        <PrivateRoute exact path="/actividad/pickups" component={ActvPickups} auth={isAuth} />
        <PrivateRoute exact path="/actividad/temporadas" component={ActvTemporadas} auth={isAuth} />
        <PrivateRoute
          exact
          path="/actividad/temporadas/cambios"
          component={CambiosTemporadas}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          // NOTA: Componer esta ruta esta mas escrito servicio
          path="/actividad/individual/nuevo-servicio/:id_entrada"
          component={ActvIndividualNuevoServicio}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/:slug"
          component={ActvIndividualEntrada}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/:slug/editar"
          component={ActvIndividualEntradaEditar}
          auth={isAuth}
          user={user}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/:id/contenido"
          component={ContenidoEntradas}
          auth={isAuth}
          user={user}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/:slug/tarifa"
          component={ActvIndividualEntradaTarifa}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/actividad/individual/nuevo-servicio/tour/:id_tour"
          component={ActvIndividualNuevoServicioTour}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/tour/:slug"
          component={ActvIndividualTour}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/tour/:slug/editar"
          component={ActvIndividualTourEditar}
          auth={isAuth}
          user={user}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/tour/:id/contenido"
          component={ContenidoTours}
          auth={isAuth}
          user={user}
        />
        <PrivateRoute
          exact
          path="/actividad/individual/tour/:slug/tarifa"
          component={ActvIndividualTourTarifa}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/booking-manager"
          component={BookingManager}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/limite-pax-service"
          component={LimitPaxService}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/tabulador-porcentaje-rep"
          component={TabulatorPercentRep}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/proveedores"
          component={transportacionProveedores}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/tipos/:id"
          component={transportacion}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/traslados"
          component={transportacion}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/excursion"
          component={transportacionExcursion}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/temporadas"
          component={transportacionTemporadas}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/unidades"
          component={transportacionUnidades}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/zonas"
          component={transportacionZonas}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/zonas/abierto"
          component={transportacionZonasAbierto}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/horas/abierto"
          component={transportacionHorasAbierto}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/zonas/abierto/:id/hoteles"
          component={transportacionZonasAbiertoByHotel}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/zonas/:id/hoteles"
          component={transportacionZonasByHotel}
          auth={isAuth}
        />
        {/* Nota Las rutas de Transportación se pueden unir */}
        <PrivateRoute
          exact
          path="/transportacion/(traslados|excursion)/tarifas/:provider"
          component={transportacionTranslateAddRate}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/transportacion/(traslados|excursion)/tarifas/abiertos/:provider"
          component={transportacionTranslateAddOpenRate}
          auth={isAuth}
        />
        <PrivateRoute exact path="/autos" component={autos} auth={isAuth} />
        <PrivateRoute exact path="/autos/agregar" component={autosAgregar} auth={isAuth} />
        <PrivateRoute exact path="/autos/temporadas" component={autosTemporadas} auth={isAuth} />
        {/* DISCOS */}
        <PrivateRoute exact path="/discos" component={discos} auth={isAuth} />
        <PrivateRoute exact path="/discos/temporadas" component={discosTemporadas} auth={isAuth} />
        <PrivateRoute
          exact
          path="/discos/:id/editar"
          component={discosEditar}
          auth={isAuth}
          user={user}
        />

        <PrivateRoute
          exact
          path="/discos/:id_disco/nuevo-servicio"
          component={discosNuevoServicio}
          auth={isAuth}
        />
        <PrivateRoute exact path="/discos/:slug/tarifa" component={discosTarifas} auth={isAuth} />
        {/* CUPONES */}
        <PrivateRoute exact path="/cupones" component={withPermissions(cupones)} auth={isAuth} />
        <PrivateRoute exact path="/cupones/agregar" component={withPermissions(cuponesAgregar)} auth={isAuth} />
        {/* CUPONES */}
        <PrivateRoute exact path="/cuponesE" component={withPermissions(cupones)} auth={isAuth} />
        <PrivateRoute exact path="/cuponesE/agregar" component={withPermissions(cuponesAgregar)} auth={isAuth} />
        <PrivateRoute exact path="/cuponesA" component={withPermissions(cupones)} auth={isAuth} />
        {/* USUARIOS */}
        <PrivateRoute exact path="/usuarios" component={usuarios} auth={isAuth} />
        <PrivateRoute exact path="/users" component={UsersIndex} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/agregar" component={usuariosAgregar} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/:id/editar" component={usuariosAgregar} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/agencias" component={agencias} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/oasis" component={agencias} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/grupos" component={agencias} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/bodas" component={agencias} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/proveedores" component={proveedores} auth={isAuth} />
        <PrivateRoute exact path="/usuarios/canales" component={ListCanales} auth={isAuth} />
        <PrivateRoute
          exact
          path="/usuarios/agencias/agregar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/oasis/agregar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/grupos/agregar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/bodas/agregar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/agencias/:id/editar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/oasis/:id/editar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/grupos/:id/editar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/bodas/:id/editar"
          component={agenciasAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/proveedores/agregar"
          component={proveedoresAgregar}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path="/usuarios/proveedores/:id/editar"
          component={proveedoresAgregar}
          auth={isAuth}
        />
        {/*Roles*/}
        <PrivateRoute exact path="/roles" component={roles} auth={isAuth} />
        <PrivateRoute exact path="/roles/usuarios" component={rolesUsuarios} auth={isAuth} />
        {/* Monedas */}
        <PrivateRoute exact path="/monedas" component={monedas} auth={isAuth} />
        <PrivateRoute exact path="/contable" component={contable} auth={isAuth} />
        {/* PROVEEDORES */}
        <PrivateRoute
          exact
          path="/proveedor/confirmation"
          component={ProveedoresConfirmation}
          auth={isAuth}
        />

        {/* CMS */}
        <PrivateRoute exact path="/cms/promociones" component={CmsPromociones} auth={isAuth} />
        <PrivateRoute exact path="/cms/servicelist" component={CmsServicesList} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cms/promociones/agregar"
          component={CmsAgregarPromocion}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cms/promociones/:id/editar"
          component={CmsEditarPromocion}
          auth={isAuth}
        />

        <PrivateRoute exact path="/cms/parques" component={CmsTours} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cms/parques/agregar"
          component={CmsAgregarParque}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cms/parques/:id/editar"
          component={CmsEditarTour}
          auth={isAuth}
        />

        <PrivateRoute exact path="/cms/promos" component={PromosIndex} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cms/promos/agregar"
          component={PromosCreate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cms/promos/:id/editar"
          component={PromosUpdate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cms/tours/:id/servicios/:idservicio/editar"
          component={CmsEditarServicio}
          auth={isAuth}
        />

        <PrivateRoute exact path="/cms/categorias" component={CmsCategorias} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings" component={CmsLandings} auth={isAuth} />
        <PrivateRoute exact path='/cms/landings/editar/:id' component={CmsLandingUpdate} auth={isAuth} />
        <PrivateRoute exact path='/cms/landings/create/:idLanding' component={CmsLandingCreate} auth={isAuth} />
        <PrivateRoute exact path="/cms/transportacion" component={CmsTraslados} auth={isAuth} />

        <PrivateRoute exact path="/cms/landings-ttos/templates" component={CmsTTosTemplates} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/templates/create" component={CmsTTosTemplatesCreate} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/templates/edit/:id" component={CmsTTosTemplatesEdit} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/landings" component={CmsTTosLandings} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/landings/create" component={CmsTTosLandingsCreate} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/landings/edit/:id" component={CmsTTosLandingsEdit} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/default-config" component={CmsTTosDefaultConfig} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/default-config/create" component={CmsTTosDefaultConfigCreate} auth={isAuth} />
        <PrivateRoute exact path="/cms/landings-ttos/default-config/edit/:id" component={CmsTTosDefaultConfigEdit} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cms/transportacion/agregar"
          //component={CmsEditarTour}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cms/transportacion/:id/editar"
          component={CmsEditarTraslado}
          auth={isAuth}
        />

        <PrivateRoute exact path="/cms/paquetes" component={CmsPaquetes} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cms/paquetes/agregar"
          component={CmsAgregarPaquete}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cms/paquetes/:id/editar"
          component={CmsEditarPaquete}
          auth={isAuth}
        />

        <PrivateRoute exact path="/cuentas/list" component={CuentasListAll} auth={isAuth} />

        <PrivateRoute exact path="/nueva/cuenta" component={NuevaCuenta} auth={isAuth} />

        <PrivateRoute exact path="/adicionar/tipo" component={AdicionarTipo} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cuenta/categoria/agregar"
          component={AgregarTipo}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cuentas/categoria/editar/:id"
          component={EditTipo}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/adicionar/cuentas/costos"
          component={CuentaCostos}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cuenta/costos/agregar"
          component={AgregarCuentaCosto}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cuentas/costo/edit/:id"
          component={EditCuentaCostos}
          auth={isAuth}
        />

        <PrivateRoute exact path="/cuentas/categoria" component={CuentasAdicionar} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cuentas/categoria/agregar"
          component={AgregarCuentaCategoria}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cuentas/categoria/edit/:id"
          component={EditCuentaCategoria}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/categorias/tipos"
          component={AdicionarTipoCategoria}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cuenta/categoria/tipo/agregar"
          component={AgregarTipoCuentaCategoria}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/cuentas/categoria/tipo/editar/:id"
          component={EditTipoCategoriaCuenta}
          auth={isAuth}
        />

        <PrivateRoute exact path="/listado/niveles" component={Cuentas} auth={isAuth} />

        <PrivateRoute exact path="/cuenta/agregar" component={AgregarCuenta} auth={isAuth} />

        <PrivateRoute exact path="/cuenta/editar/:id" component={EditCuenta} auth={isAuth} />

        <PrivateRoute exact path="/listado/cuenta" component={CuentaNivel} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cuenta/agregar/recursos"
          component={AgregarCuentaNivel}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/listado/cuenta/editar/recursos/:id"
          component={EditCuentaRecurso}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/reportes/cuentas/contables"
          component={ReporteVentaContable}
          auth={isAuth}
        />

        <PrivateRoute exact path="/grupos/cuentas" component={GrupoCuentas} auth={isAuth} />

        <PrivateRoute exact path="/grupos/agregar" component={GrupoAgregar} auth={isAuth} />
        <PrivateRoute exact path="/grupo/edit/:id" component={GrupoEdit} auth={isAuth} />

        <PrivateRoute
          exact
          path="/cuentas/constables/edit/:id"
          component={NuevaCuenta}
          auth={isAuth}
        />

        <PrivateRoute exact path="/rbac/users" component={UsersList} auth={isAuth} />

        <PrivateRoute exact path="/rbac/users/:id" component={UserView} auth={isAuth} />

        <PrivateRoute exact path="/rbac/roles" component={RolesList} auth={isAuth} />

        <PrivateRoute exact path="/rbac/roles/:id" component={RolesView} auth={isAuth} />

        <PrivateRoute exact path="/rbac/roles-create" component={RolesCreate} auth={isAuth} />

        <PrivateRoute exact path="/rbac/permissions" component={PermissionsList} auth={isAuth} />

        <PrivateRoute
          exact
          path="/rbac/permissions/:id"
          component={PermissionsView}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/rbac/permissions-create-extranet"
          component={PermissionsCreateExtranet}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/rbac/permissions-create-crm"
          component={PermissionsCreateCrm}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/rbac/replicate-permissions'
          component={ReplicatePermissions}
          auth={isAuth}
        />

        <PrivateRoute exact path='/rbac/replicate-user-services' component={ReplicateUsers} auth={isAuth} />


        {/* <PrivateRoute
          exact
          path='/metas/ejecutivo'
          component={metasEjecutivoIndex}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/ejecutivo/create'
          component={metasEjecutivoCreate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/ejecutivo/update/:id'
          component={metasEjecutivoUpdate}
          auth={isAuth}
        /> */}

        <PrivateRoute
          exact
          path='/metas/subgerente'
          component={metasSubGerenteIndex}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/subgerente/create'
          component={metasSubGerenteCreate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/subgerente/update/:id'
          component={metasSubGerenteUpdate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/supervisor'
          component={metasSupervisorIndex}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/supervisor/create'
          component={metasSupervisorCreate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/supervisor/update/:id'
          component={metasSupervisorUpdate}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/metas/supervisor/replicar'
          component={metasSupervisorReplicar}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/services-promo-details/index'
          component={PromoServicesDetailsIndex}
          auth={isAuth}
        />
        <PrivateRoute
          exact
          path='/replicar-productos'
          component={ReplicarProductos}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/services/availability-by-users'
          component={ServicesAvailabilityIndex}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/services/availability-by-users/discs'
          component={AvailabilityDiscs}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/services/availability-by-users/tours'
          component={AvailabilityTours}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/services/availability-by-users/tickets'
          component={AvailabilityTickets}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/services/availability-by-users/packages'
          component={AvailabilityPackages}
          auth={isAuth}
        />
        <PrivateRoute exact path="/propinas" component={Propinas} auth={isAuth} />
        <PrivateRoute exact path="/politicas/get" component={Politicas} auth={isAuth} />

        <PrivateRoute exact path="/labels/index" component={LabelsIndex} auth={isAuth} />
        <PrivateRoute exact path="/labels/create" component={LabelsCreate} auth={isAuth} />
        <PrivateRoute exact path="/labels/update/:id" component={LabelsUpdate} auth={isAuth} />

        <PrivateRoute exact path="/payment-rules/index" component={PaymentRulesIndex} auth={isAuth} />
        <PrivateRoute exact path="/payment-rules/create" component={PaymentsRulesCreate} auth={isAuth} />
        <PrivateRoute exact path="/payment-rules/:id" component={PaymentRulesUpdate} auth={isAuth} />

        <PrivateRoute exact path="/services-labels" component={ServicesLabelsIndex} auth={isAuth} />
        <PrivateRoute exact path="/services-labels/tickets-parks/:id" component={TicketsParksList} auth={isAuth} />
        <PrivateRoute exact path="/services-labels/tickets-services/:id" component={TicketsServicesList} auth={isAuth} />

        <PrivateRoute exact path="/services-labels/tours-parks/:id" component={ToursParksList} auth={isAuth} />
        <PrivateRoute exact path="/services-labels/tours-services/:id" component={ToursServicesList} auth={isAuth} />

        <PrivateRoute exact path="/services-rules" component={ServicesRulesIndex} auth={isAuth} />

        <PrivateRoute exact path="/services-rules/tickets-parks/:id" component={TicketsParksRulesList} auth={isAuth} />
        <PrivateRoute exact path="/services-rules/tickets-services/:id" component={TicketsServicesRulesList} auth={isAuth} />

        <PrivateRoute exact path="/services-rules/tours-parks/:id" component={ToursParksRulesList} auth={isAuth} />
        <PrivateRoute exact path="/services-rules/tours-services/:id" component={ToursServicesRulesList} auth={isAuth} />

        <PrivateRoute exact path="/user-inceptives" component={UserInceptives} auth={isAuth} />
        <PrivateRoute exact path="/user-inceptives/create" component={UserInceptivesCreate} auth={isAuth} />
        <PrivateRoute exact path="/comprobante/pago" component={ComprobantePago} auth={isAuth} />

        <PrivateRoute exact path="/departments" component={DepartmentsIndex} auth={isAuth} />
        <PrivateRoute exact path="/auth-audit" component={LoginLogsIndex} auth={isAuth} />
        <PrivateRoute exact path="/services-labels/list" component={ServicesInceptivesLabels} auth={isAuth} />

        <PrivateRoute exact path="/booking-logs" component={SaveBookingLogs} auth={isAuth} />
        <PrivateRoute exact path="/nim-services/index" component={NimServicesIndex} auth={isAuth} />

        <PrivateRoute exact path='/services/labels-and-rules' component={LabelsAndRules} auth={isAuth} />

        <PrivateRoute exact path='/cms/discs' component={CmsDiscsIndex} auth={isAuth} />
        <PrivateRoute exact path='/cms/discs/create' component={CmsDiscsCreate} auth={isAuth} />
        <PrivateRoute exact path='/cms/discs/update/:id' component={CmsDiscsUpdate} auth={isAuth} />
        <PrivateRoute exact path="/cms/discs/:id/servicios/:idservicio/editar" component={CmsDiscsEditService} auth={isAuth} />

        <PrivateRoute exact path='/cms/banners' component={CmsBannersIndex} auth={isAuth} />

        <PrivateRoute exact path='/catalog-categories' component={CatalogCategories} auth={isAuth} />
        <PrivateRoute exact path='/catalog-amenities' component={CatalogAmenities} auth={isAuth} />

        <PrivateRoute exact path='/cms/testimonios' component={CmsTestimonialsIndex} auth={isAuth} />

        <PrivateRoute exact path='/cms/secciones' component={CmsSections} auth={isAuth} />
        <PrivateRoute exact path='/cms/secciones/:id/plantillas' component={CmsSectionsTemplates} auth={isAuth} />
        <PrivateRoute exact path='/cms/secciones/:id/plantillas/create' component={CmsSectionsTemplatesCreate} auth={isAuth} />
        <PrivateRoute exact path='/cms/secciones/:id/plantilla/:template_id/editar' component={CmsSectionsTemplateUpdate} auth={isAuth} />

        <PrivateRoute exact path='/cms/promociones_new' component={CmsPromotions} auth={isAuth} />
        <PrivateRoute exact path='/cms/promociones_new/agregar' component={CmsPromotionsCreate} auth={isAuth} />
        <PrivateRoute exact path='/cms/promociones_new/:id/editar' component={CmsPromotionsUpdate} auth={isAuth} />

        <PrivateRoute
          exact
          path='/cms/presentacion-vcm'
          component={LandingTransportation}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/cms/clip-3d'
          component={Clip3D}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path='/cms/landing-transportacion/:id/:typeSection/editar'
          component={UpdateSectionLandingTransportation}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/discos/combos"
          component={combosDiscos}
          auth={isAuth}
        />

        <PrivateRoute
          exact
          path="/usuarios/canales/configuracion-de-vistas"
          component={ConfigurationView}
          auth={isAuth}
        />

        {/* Page Not Found */}
        {/* TODO: Make a 404 Page */}
        <Route component={() => <div>Error 404: No se encontró la página</div>} />
      </Switch>
    </Suspense>
  );
};
export default Router;
// export default withRouter(Router);
